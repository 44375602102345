import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { graphql, Link } from 'gatsby';
import { Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { orderBy } from 'lodash';
import { renderAbstract } from '@utils';
import '@scss/seniors-page.scss';

const SeniorsListPage = ({ data }) => {
  const { allSeniorProjects } = data.postgres;
  const [ selectedCategory, setSelectedCategory ] = useState(`All`);
  const categories = [ `All`, ...new Set(allSeniorProjects?.map(s => s.topic)) ];
  const renderSeniorProjBlocks = () => orderBy(allSeniorProjects, `title`)
    .filter(project => selectedCategory !== `All` ? project.topic === selectedCategory : true)
    ?.map(item =>
      <Col md={6} lg={3} key={item.id}>
        <div className="project">
          <div className="name">
            <h2>{item.title}</h2>
          </div>
          <div className="project-overlay">
            <Link to={`/${item.year}/seniors/${item.id}`}>
              <p>
                { renderAbstract(item.abstract) }
              </p>
            </Link>
          </div>
        </div>
      </Col>);
  return <Layout className="seniors-page">
    <Navbar year={allSeniorProjects[0].year} />
    <PageHeader
      title="Senior Design Projects"
      titleTransform="uppercase"
      subTextComponent={<Container>
        <Row className="align-items-center">
          <Col style={{ padding: `10px` }} md={{ offset: 4, span: 4 }}>
            <Form.Label className="text-white">Select Project Category:</Form.Label>
            <Form.Control as="select" value={selectedCategory} onChange={(e) =>
              setSelectedCategory(e.target.value === selectedCategory ? null : e.target.value)}>
              {categories.map(category =>
                <option key={category} value={category}>{category}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </Container>}
    />

    <Container className="projects-div">
      <Row>
        {renderSeniorProjBlocks()}
      </Row>
    </Container>

    <Sponsor year={allSeniorProjects[0].year} />
    <Footer />

  </Layout>;
};

export const query = graphql`
  query SeniorsList($year: String!) {
    postgres {
      allSeniorProjects: allSeniorDesignProjectsList(condition: { year: $year }) {
        id
        title
        topic
        abstract
        year
      }
    }
  }
`;

export default SeniorsListPage;
